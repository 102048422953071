import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import HomepageContent from './HomepageContent';
import './styles.scss';
import FormPage from "../form/FormPage";

export default function Homepage() {
  return (
    <AppLayout
      content={<HomepageContent />}
      //   content={<FormPage />}
        contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}
