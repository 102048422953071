import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Link from '@amzn/awsui-components-react/polaris/link';
import * as tokens from '@amzn/awsui-design-tokens';

export default function CommonHeader() {
  return (
    <div style={{ background: tokens.colorBackgroundHomeHeader }}>
      <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } }
          ]}
        >
          <div className="custom-home-text-inverted">
            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
              Autorip Self service tool
            </Box>
            {/*<Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">*/}
            {/*  */}
            {/*</Box>*/}
            <Box variant="p" fontWeight="light">
              <span className="custom-home-text-secondary">

                {/*Build your own app in minutes using &nbsp;*/}
                {/*<Link href="https://w.amazon.com/bin/view/CorpAppsInAWS/#HCode" target="_blank">*/}
                {/*  code templates*/}
                {/*</Link>*/}
              </span>
            </Box>
          </div>
        </Grid>
      </Box>
    </div>
  );
}