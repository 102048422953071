import { PetsApi } from './generated-src';
import { getAppSetting } from '../config/AppSettings';
import { Configuration } from "./generated-src/configuration";

export default function() {
  const apiBasePath = `https://${getAppSetting('apiUrl')}/jwt`;
  return new PetsApi(new Configuration({basePath: apiBasePath}));
}

//"apiUrl": "d-h97s9xji5l.execute-api.us-east-2.amazonaws.com"
//  "apiUrl": "api.amazonmusicsupport.amazon.dev"
//  "apiUrl": "api.autorip.amazonmusicsupport.amazon.dev"
// "apiUrl": "vivecb.amazon.com"