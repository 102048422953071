import * as React from "react";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Input from "@amzn/awsui-components-react/polaris/input";
import {ColumnLayout, SpaceBetween} from "@amzn/awsui-components-react";
import FormInput from "./FormInput";
import {useState} from "react";
import {Autorip, AutoripApiFactory} from "../../pets-api/generated-src";
import PetsApiFactory from "../../pets-api/PetsApiFactory";
import Button from "@amzn/awsui-components-react/polaris/button";
import {AutoripApiFp} from "../../pets-api/generated-src";
import {AutoripApiAxiosParamCreator} from "../../pets-api/generated-src";


const AutoripApi = AutoripApiFactory();


export default () => {
    const [dropdownselectedValue, setdropdownselectedValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState("");
    const inputData: Autorip = {
        PhysicalASIN: inputValue,
        Territory: dropdownselectedValue
    };
    async function autoripHandler() {
        console.log(inputData);
        // await AutoripApi.autoripChecker(inputData);
        // await AutoripApi.autoripRetriever(inputData);
        await AutoripApi.autoripChecker(inputData);
        // history.push('/');
    }
    // React.useEffect(() => {
    //     document.title = `You clicked ${dropdownselectedValue} times`;
    //     // console.log("type:",type)
    // },[dropdownselectedValue]);




    return (
        <FormField
            label= "Provide the CD/Physical ASIN and select territory"
        >
            <ColumnLayout columns={2}>
                {/*<Input controlId="physicalASIN"  value={"physicalASIN"}/>*/}
                {/*<FormInput/>*/}
                <FormField
                    // description="This should be CD/Physical ASIN."
                    // label="Provide the CD/Physical ASIN"
                >
                    <Input
                        value={inputValue}
                        onChange={event =>
                            setInputValue(event.detail.value)
                        }
                    />
                </FormField>

                <ButtonDropdown
                    // ariaLabel={dropdownselectedValue}
                    items={[
                        { text: "US", id: "US", disabled: false },
                        { text: "DE", id: "DE", disabled: false },
                        { text: "ES", id: "ES", disabled: false },
                        { text: "FR", id: "FR", disabled: false },
                        { text: "IT", id: "IT", disabled: false },
                        { text: "GB", id: "GB", disabled: false }
                    ]}
                    onItemClick={event =>
                        setdropdownselectedValue(event.detail.id)}
                >
                    Territory :  {dropdownselectedValue}
                </ButtonDropdown>

                <SpaceBetween direction="horizontal" size="xs">
                        {/*<Button variant="normal" href="#/">Cancel</Button>*/}
                    {/*<Button variant="primary" onClick={autoripHandler}>Submit</Button>*/}
                    <Button variant="primary" onClick={autoripHandler}>Submit</Button>

                </SpaceBetween>
            </ColumnLayout>
        </FormField>
    );
}
